<template>
  <div>
    <v-checkbox
      hide-details
      v-model="computedHideFullname"
      :label="$t('t.HideFullname')"
      color="secondary"
    />
    <v-checkbox
      hide-details
      v-model="computedHideEmail"
      :label="$t('t.HideEmail')"
      color="secondary"
    />
    <v-checkbox
      hide-details
      v-model="computedHidePhone"
      :label="$t('t.HidePhone')"
      color="secondary"
    />
  </div>
</template>

<script>
export default {
  computed: {
    computedHidePhone: {
      get () { return this.dataHidePhone },
      set (v) {
        this.dataHidePhone = v
        this.dataDocumentEmit()
      }
    },
    computedHideEmail: {
      get () { return this.dataHideEmail },
      set (v) {
        this.dataHideEmail = v
        this.dataDocumentEmit()
      }
    },
    computedHideFullname: {
      get () { return this.dataHideFullname },
      set (v) {
        this.dataHideFullname = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        hidePhone: this.dataHidePhone, hideEmail: this.dataHideEmail, hideFullname: this.dataHideFullname
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataHidePhone: false,
      dataHideEmail: false,
      dataHideFullname: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const u = this.lodash.cloneDeep(v)
        this.dataHidePhone = u?.hidePhone ?? false
        this.dataHideEmail = u?.hideEmail ?? false
        this.dataHideFullname = u?.hideFullname ?? false
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
